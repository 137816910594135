<template>
  <div class="main-body">
    <div class="doc-body">
      <div class="doc-category">
        <a-tree style="width:180px" :auto-expand-parent="true" :default-expand-all="true" v-if="treeDataAll[0]['children'].length" v-model:selectedKeys="selectedKeys" :tree-data="treeDataAll" :field-names="fieldNames" @select="selectCategory">
          <template #switcherIcon="{ switcherCls }">
            <down-outlined :class="switcherCls" />
          </template>
        </a-tree>
        <a-skeleton v-else />
      </div>
      <div class="doc-list">
        <div class="table-search">
          <a-form :model="formState" layout="inline" autocomplete="off" @submit="onLoadList">
            <a-form-item label="标题">
              <a-input v-model:value="formState.keyword" placeholder="请输入标题关键字" />
            </a-form-item>
            <a-form-item label="发布时间">
              <a-range-picker format="YYYY-MM-DD" v-model:value="formState.betweenTime" />
            </a-form-item>
            <a-form-item class="search-btn">
              <a-button type="primary" html-type="submit">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-button @click="resetSearchForm">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="table-operator">
          <a-row>
            <a-col :span="12" class="line-left">
              <a-button type="primary" @click="publish" :disabled="is_allow_publish">
                <PlusOutlined />发布
              </a-button>
            </a-col>
            <a-col :span="12" class="lin-right">
              <span>
                <template v-if="hasSelected">
                  {{ `您选择了 ${selectedRowKeys.length} 项` }}
                </template> </span>
              <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="batch('move')" class="margin-left15" size="small"> 移动 </a-button>
              <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="batch('onder')" class="margin-left15" size="small"> 上架 </a-button>
              <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="batch('under')" class="margin-left15" size="small"> 下架 </a-button>
              <a-button type="primary" danger :disabled="!hasSelected" :loading="loading" class="margin-left15" size="small" @click="batch('delete')"> 删除 </a-button>
            </a-col>
          </a-row>

        </div>
        <a-table rowKey="article_id" row-class-name="article-list" :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange, }" :pagination="pagination" @change="onLoadList" :columns="columns" :data-source="formState.articleList">
          <template #bodyCell="{ column,record }">
            <template v-if="column.dataIndex === 'title'">
              <span class="article-title">
                <a-tag color="#87d068" v-if="record.content_type == 'download'">下载</a-tag>
                <a-tag color="#faad14" v-if="record.content_type == 'photos'">图集</a-tag>
                {{record.title}}
              </span>
            </template>
            <template v-if="column.dataIndex === 'sort'">
              <a-input v-model:value="record.sort" @blur="save(record.sort,record.article_id)" />
            </template>
            <template v-if="column.dataIndex === 'status'">
              <span v-if="record.status == 1" style="color: #b7eb8f"> 正常 </span>
              <span v-else style="color: #ffe58f">待上架</span>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <span>

                <a-button type="link" @click.stop="update(record.article_id)">编辑</a-button>

                <a-dropdown>
                  <a-button type="text" @click.prevent>更多
                    <DownOutlined />
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a-button type="text" @click.stop="preview(record.article_id)">预览</a-button>
                      </a-menu-item>
                      <a-menu-item>
                        <a-button type="text" danger @click="deleteConfirm(record.article_id)">删除</a-button>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </span>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>

  <a-modal v-model:visible="move_visible" title="移动" @ok="moveHandleSubmit" :width="500">
    <div style="padding:20px;">
      <a-form ref="formRef" :model="moveFormState">
        <a-form-item label="提示">
          <span class="ant-form-text">请选择移动至栏目，已在对应栏目的数据将移动失败.</span>
        </a-form-item>
        <a-form-item has-feedback name="category_id" :rules="[{ required: true, message: '请选择栏目!' }]">
          <a-tree-select key="category_id" show-search :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }" v-if="treeData.length" tree-default-expand-all placeholder="请选择栏目" allow-clear :field-names="fieldNames" :tree-data="treeData" selectable v-model:value="moveFormState.category_id">
          </a-tree-select>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>
<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  toRefs,
  onMounted,
} from "vue";
import { PlusOutlined, DownOutlined } from "@ant-design/icons-vue";
import * as Api from "@/views/document/api";
import { message, Modal } from "ant-design-vue";
import "./style/list.less";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  components: {
    DownOutlined,
    PlusOutlined,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const category_id = route.query.category_id ? route.query.category_id : 0;

    // 发布文档
    const publish = () => {
      let current_category_id = selectedKeys.value[0];
      // console.log("当前分类ID：", current_category_id);
      router.push({
        path: "/document/content/publish",
        query: { category_id: current_category_id },
      });
    };

    // 更新文档
    const update = (article_id) => {
      router.push({
        path: "/document/content/update",
        query: { id: article_id },
      });
    };

    // 列表请求条件
    const formState = reactive({
      category_id: category_id,
      keyword: "",
      betweenTime: [],
      page: 1,
      articleList: [],

      total: 0,
      current_page: 0,
      per_page: 0,
    });

    // 列表分页数据
    const pagination = computed(() => ({
      total: formState.total,
      current: formState.current_page,
      pageSize: formState.per_page,
    }));

    const is_allow_publish = ref(false);

    const save = (sort, article_id) => {
      Api.asort({ sort: sort, article_id: article_id }).then((res) => {
        if (res.status === 200) {
          onLoadList();
        }
      });
    };
    // 根据条件获取列表数据
    const onLoadList = (page, filters, sorter) => {
      Api.getArticleList({
        page: page?.current,
        limit: page ? page.pageSize : 10,
        category_id: formState.category_id,
        keyword: formState.keyword,
        betweenTime: formState.betweenTime,
        sorter: sorter ? sorter : false,
      }).then((res) => {
        formState.articleList = res.data.data;
        formState.total = res.data.total;
        formState.per_page = res.data.per_page;
        formState.current_page = res.data.current_page;
      });

      // 检查是否允许发布、判断是否是单页 单页存在则不允许再发布
      Api.check({
        category_id: formState.category_id,
      }).then((res) => {
        is_allow_publish.value = !res.data;
      });
    };

    // 重置表单数据
    const resetSearchForm = () => {
      formState.keyword = "";
      formState.betweenTime = [];
      state.selectedRowKeys = [];
      moveFormState.category_id = "";
      onLoadList();
    };

    // 左侧分类数据
    const treeData = ref([]);

    const treeDataAll = ref([
      {
        category_id: 0,
        category_name: "全部",
        parent_id: 0,
        code: "all",
        sort: 100,
        status: true,
        children: [],
      },
    ]);

    // 获取左侧分类数据
    const refreshCategoryData = () => {
      Api.getCategoryList().then((res) => {
        if (res.status === 200) {
          treeData.value = res.data;
          res.data.filter(function (item) {
            treeDataAll.value[0]["children"].push(item);
          });
        } else {
          message.error("栏目数据获取出错了");
        }
      });
    };

    // 左侧导航打开和选择项
    const expandedKeys = ref([]);
    const selectedKeys = ref([0]);

    // 移动文档
    const move_visible = ref(false);

    const openMoveModal = () => {
      move_visible.value = true;
    };

    // 移动请求表单数据
    const formRef = ref();
    const moveFormState = reactive({
      category_id: "",
    });

    // 移动提交数据操作
    const moveHandleSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          Api.articleMoveByIds({
            ids: state.selectedRowKeys,
            category_id: moveFormState.category_id,
          }).then((res) => {
            if (res.status == 200) {
              // 刷新列表
              resetSearchForm();
              onLoadList();
              message.success(res.message);
            } else {
              message.error(res.message);
            }
            move_visible.value = false;
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    // 上架提交数据操作
    const onderHandleSubmit = (keys) => {
      Api.articleOnderByIds({
        ids: keys,
      }).then((res) => {
        if (res.status == 200) {
          // 刷新列表
          onLoadList();
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      });
    };

    // 下架提交数据操作
    const underHandleSubmit = (keys) => {
      Api.articleUnderByIds({
        ids: keys,
      }).then((res) => {
        if (res.status == 200) {
          // 刷新列表
          onLoadList();
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      });
    };

    // 多选选项
    const state = reactive({
      selectedRowKeys: [],
      loading: false,
    });

    //多选
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys;
    };

    const hasSelected = computed(() => state.selectedRowKeys.length > 0);

    //批量操作
    const batch = (event) => {
      let keys = state.selectedRowKeys;
      switch (event) {
        case "move":
          // 批量移动
          openMoveModal(); // 打开选择移动的栏目
          break;
        case "onder":
          // 批量上架
          onderHandleSubmit(keys);
          break;
        case "under":
          // 批量下架
          underHandleSubmit(keys);
          break;
        case "delete":
          // 批量删除
          deleteConfirm(keys);
          break;
        default:
          break;
      }

      state.selectedRowKeys = [];
    };

    //栏目选择
    const selectCategory = (event) => {
      if (event.length > 0) {
        formState.category_id = event[0];
        selectedKeys.value = [event[0]];
      } else {
        formState.category_id = 0;
        selectedKeys.value = [0];
      }
      onLoadList();
    };

    //删除文档提示框
    const deleteConfirm = (data) => {
      Modal.confirm({
        title: "删除后会先放入回收站，彻底删除请进入回收站！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          deleteHandle(data);
        },
      });
    };

    // 删除操作和批量删除
    const deleteHandle = (ids) => {
      Api.articleDeleteByIds({
        ids: ids,
      }).then((res) => {
        if (res.status == 200) {
          // 刷新列表
          onLoadList();
          message.success(res.message);
        } else {
          message.error(res.message);
        }
      });
    };

    // 预览
    const preview = (article_id) => {
      Api.getArticleDetail({
        article_id: article_id,
      }).then((res) => {
        if (res.status == 200) {
          let myWindow = window.open(
            "",
            "popwindow",
            "height=667,width=380,top=100,left=500,toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no,titlebar=no"
          );
          myWindow.document.write(res.data.content);
          myWindow.focus();
        }
      });
    };

    onMounted(() => {
      // 获取左侧分类数据
      refreshCategoryData();

      // 回显跳转选中的分类
      if (category_id > 0) {
        selectedKeys.value = [parseInt(category_id)];
      }

      // 获取列表数据
      onLoadList();
    });

    return {
      publish,
      save,
      update,
      is_allow_publish,
      // 页面数据
      formState,
      pagination,
      onLoadList,
      //重置表单
      resetSearchForm,
      // 左侧分类
      fieldNames: {
        key: "category_id",
        title: "category_name",
        label: "category_name",
        children: "children",
        value: "category_id",
      },
      treeData,
      treeDataAll,
      expandedKeys,
      selectedKeys,
      // 移动操作
      move_visible,
      openMoveModal,
      formRef,
      moveFormState,
      moveHandleSubmit,
      // 左侧选择
      selectCategory,
      // 多选操作
      hasSelected,
      ...toRefs(state),
      batch,
      onSelectChange,
      refreshCategoryData,
      deleteConfirm,
      preview,
      columns: [
        {
          title: "ID",
          dataIndex: "article_id",
          width: "5%",
        },
        {
          title: "标题",
          dataIndex: "title",
          width: "40%",
        },
        {
          title: "排序",
          dataIndex: "sort",
          sorter: true,
          width: "7%",
        },
        {
          title: "发布时间",
          dataIndex: "push_time",
          sorter: true,
          width: "15%",
        },
        {
          title: "状态",
          dataIndex: "status",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: "25%",
        },
      ],
    };
  },
});
</script>